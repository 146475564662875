export const routes = [
    {
        path: '/',
        component: () => import(/* webpackChunkName: "Common" */ './layouts/index.vue'),
        children: [
            // Home
            {
                path: '/',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/Home.vue')
            },

            // List
            {
                path: '/list',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/List.vue')
            },

            // 테스트
            {
                path: '/test',
                component: () => import(/* webpackChunkName: "Common" */ '@/tests/test.vue')
            },

            // 마이페이지
            {
                path: '/mypage',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/Mypage.vue')
            },

            // Auth
            {
                path: 'auth',
                component: () => import(/* webpackChunkName: "Common" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'login',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/auth/Login.vue')
                    },
                    {
                        path: 'logout',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/auth/Logout.vue')
                    },
                    {
                        path: 'sns',
                        component: () => import(/* webpackChunkName: "Common" */ '@/layouts/empty.vue'),
                        children: [
                            {
                                path: 'google',
                                component: () => import(/* webpackChunkName: "Common" */ '@/views/auth/sns/google.vue')
                            },
                        ]
                    },
                    {
                        path: 'join',
                        component: () => import(/* webpackChunkName: "Common" */ '@/layouts/empty.vue'),
                        children: [
                            {
                                path: 'agree',
                                component: () => import(/* webpackChunkName: "Common" */ '@/views/auth/join/agree.vue')
                            },
                            {
                                path: 'form',
                                component: () => import(/* webpackChunkName: "Common" */ '@/views/auth/join/form.vue')
                            }
                        ]
                    },
                    {
                        path: 'find',
                        component: () => import(/* webpackChunkName: "Common" */ '@/layouts/empty.vue'),
                        children: [
                            {
                                path: 'id',
                                component: () => import(/* webpackChunkName: "Common" */ '@/views/auth/find/id.vue')
                            },
                            {
                                path: 'password',
                                component: () => import(/* webpackChunkName: "Common" */ '@/views/auth/find/password.vue')
                            }
                        ]
                    },
                    {
                        path: 'email_certification',
                        component: () => import(/* webpackChunkName: "Auth" */ '@/views/auth/EmailCertification.vue')
                    }
                ]
            },

            // Policy
            {
                path: 'policy',
                component: () => import(/* webpackChunkName: "Policy" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'rule',
                        component: () => import(/* webpackChunkName: "Policy" */ '@/views/policy/Rule.vue'),
                    },
                    {
                        path: 'privacy',
                        component: () => import(/* webpackChunkName: "Policy" */ '@/views/policy/Privacy.vue'),
                    },
                ]
            }
        ]
    },

    // 관리자 페이지
    {
        path: '/admin',
        meta: { sitemap: { ignoreRoute: true } },
        component: () => import(/* webpackChunkName: "Admin" */ '@/layouts/admin/index.vue'),
        children: [
            // Auth
            {
                path: 'auth',
                component: () => import(/* webpackChunkName: "Admin" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'login',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/auth/login.vue')
                    },
                    {
                        path: 'logout',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/auth/logout.vue')
                    },
                ]
            },

            // 대시보드
            {
                path: 'dashboard',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/admin/dashboard.vue')
            },

            // 이용약관 관리
            {
                path: 'rule',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/admin/rule.vue')
            },

            // 개인정보처리방침 관리
            {
                path: 'privacy',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/admin/privacy.vue')
            },

            // 회원 관리
            {
                path: 'user',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/admin/user.vue'),
            },

            // 관리자 정보수정
            {
                path: 'update',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/admin/update.vue'),
            }
        ]
    }
]