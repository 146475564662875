const methods = {
    // # 수정모드 > 사이즈 측정
    getTextWidth(text, fontSize, fontWeight, lineHeight, letterSpacing, fontFamily) {
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        context.font = `${fontWeight} ${fontSize} ${fontFamily}`
        context.letterSpacing = letterSpacing
        context.lineHeight = lineHeight
        return context.measureText(text).width
    },

    // # 수정모드 > 사이즈 조절
    dynamicWidth(item) {
        const fontSize = '1.5rem'
        const fontWeight = '400'
        const lineHeight = '2rem'
        // const letterSpacing = '0.0125em'
        const letterSpacing = '0'
        const fontFamily = '"Noto Sans KR", sans-serif'
        return this.$getTextWidth(item.edit_number, fontSize, fontWeight, lineHeight, letterSpacing, fontFamily) + 'px'
    },

    // # 수정모드 시작
    edit_start(list, item){
        // 다른 수정들 모두 해제
        list.forEach(e => e.edit = false)
        list.forEach(e => e.edit2 = false)

        // 수정모드로 상태값 변경
        item.edit = true

        // % 가 있는 경우
        if(item.number.toString().endsWith('%')){
            item.edit_number = this.$toComma(item.number)
        }

        // 그 외
        else{
            // # 서브탭 000
            if(this.$store.state.sub_tab == "000"){
                // 단위 없을 시
                if(item.scale == null){
                    // 수정값 = number
                    item.edit_number = this.$toComma(this.$math.bignumber(item.number)).trim()
                }
                // 단위 있을 시
                else{
                    // 수정값 = number X 단위
                    item.edit_number = this.$toComma(this.$caculateTo000(item.number, item.scale)).trim()

                    // 단위 초기화
                    item.scale = null
                }
            }

            // # 서브탭 : 만억조
            else if(this.$store.state.sub_tab == "만억조"){
                // 단위 없을 시
                if(item.scale == null){
                    // 수정값 = number 만억조 변환값
                    item.edit_number = this.$toComma(this.$caculateNumberToMOJ(item.number)).trim()
                }
                // 단위 있을 시
                else{
                    // 수정값 = number + 단위
                    item.edit_number = item.number + item.scale

                    // 단위 초기화
                    item.scale = null
                }
            }

            // # 서브탭 : KMB
            else if(this.$store.state.sub_tab == "KMB"){
                // 단위 없을 시
                if(item.scale == null){
                    // 수정값 = number KMB 변환값
                    item.edit_number = this.$toComma(this.$caculateNumberToKMB(item.number)).trim()
                }
                // 단위 있을 시
                else{
                    // 수정값 = number + 단위
                    item.edit_number = item.number + item.scale

                    // 단위 초기화
                    item.scale = null
                }
            }
        }

        // 수정모드로 VUEX 업데이트
        this.$store.commit('update_edit_mode', true)
        this.$store.commit('update_edit_mode2', false)

        // 비활성 키 체크
        this.$checkDisableKey(list)
    },

    // # 수정모드 종료
    edit_end(list){
        console.log("edit_end")
        
        // 수정중인 아이템이 없는 경우 바로 종료
        const isEditing = list.some(item => item.edit)
        if (!isEditing) {
            return
        }

        // 수정모드로 VUEX 업데이트
        this.$store.commit('update_edit_mode', false)

        // 비활성 키 체크
        this.$checkDisableKey(list)

        // # 수정값 세팅
        let editIndex = list.findIndex(item => item.edit)
        let editItem = list[editIndex]
        let isLastItem = editIndex === list.length - 1

        // # 빈값 종료시
        if(editItem.edit_number == null || editItem.edit_number == ""){
            editItem.number = null
            editItem.edit = false
            return
        }

        // # % 가 있는 경우
        if(editItem.number.toString().endsWith('%')){
            editItem.number = this.$toUnComma(editItem.edit_number)
            editItem.edit = false
            return
        }

        // # 서브탭 : 000
        if(this.$store.state.sub_tab == "000"){
            // 최종 수정값이 없을때
            if (editItem.edit_number === "" || editItem.edit_number == 0) {
                // 마지막 요소이면 숫자만 제거
                if(isLastItem){
                    editItem.number = null
                    editItem.edit = false
                }

                // 그 외에는 연산자도 같이 제거
                else{
                    this.list.splice(editIndex, 1)
                }
            }
            
            // 최종 수정값이 있을때
            else{
                editItem.number = this.$toUnComma(editItem.edit_number)
                editItem.edit = false
            }
        }

        // # 서브탭 : 만억조 or KMB
        else{
            // 최종 수정값이 없을때
            if (editItem.edit_number === "" || editItem.edit_number == 0) {
                // 마지막 요소이면 숫자만 제거
                if(isLastItem){
                    editItem.number = null
                    editItem.edit = false
                }

                // 그 외에는 연산자도 같이 제거
                else{
                    this.list.splice(editIndex, 1)
                }
            }
            
            // 최종 수정값이 있을때
            else {
                const scales = {
                    '만': 10000,
                    '억': 100000000,
                    '조': 1000000000000,
                    '경': 10000000000000000,
                    'K': 1000,
                    'M': 1000000,
                    'B': 1000000000,
                    'T': 1000000000000
                }

                let editNumber = editItem.edit_number

                // scales의 key값 뒤에 공백 추가
                for (const key of Object.keys(scales)) {
                    const regex = new RegExp(`(${key})(?!\\s)`, 'g')
                    editNumber = editNumber.replace(regex, `$1 `)
                }

                // 공백을 기준으로 분리하고 숫자로 변환
                const parts = editNumber.split(' ')
                    .filter((part, index, array) => {
                        // part가 공백이 아니고 마지막 요소가 아닌데 scales의 key값에 해당하는 문자만 있는 경우 제거
                        const isScaleOnly = Object.keys(scales).some(key => part === key)

                        // part가 숫자, '.', ','로만 이루어진 경우 제거 (마지막 요소가 아닌 경우에만)
                        const isNumericOnly = index !== array.length - 1 && /^[0-9.,]+$/.test(part.trim())

                        return !isScaleOnly && !isNumericOnly
                    })
                    .filter(part => part.trim() !== '') // 빈 문자열 제거
                    .map(part => {
                        for (const [key, value] of Object.entries(scales)) {
                            part = this.$toUnComma(part)

                            if (part.endsWith(key)) {
                                const numberPart = this.$math.bignumber(part.replace(key, ''))
                                return this.$math.multiply(numberPart, value)
                            }
                        }
                        return this.$math.bignumber(part)
                    })

                console.log(parts)

                // 모든 숫자를 더함
                const totalNumber = parts.reduce((acc, num) => this.$math.add(acc, num), 0)
                
                console.log(totalNumber)

                // 유효한 숫자인지 확인
                const number = this.$toUnComma(totalNumber.toString().trim())

                console.log(number)

                if (isNaN(number)) {
                    editItem.number = 0 // 기본값 설정
                } else {
                    editItem.number = number
                }

                editItem.edit = false
            }
        }
    },
}

export default methods