<template>
    <v-app :class="rotateClass">
        <router-view />
    </v-app>
</template>
<script>
export default {
    name: 'App',

    watch: {
        $route(to, from) {
            if(to.path != "/" && this.$store.state.rotate){
                this.$store.commit("update_rotate", false)
            }
        }
    },

    computed: {
        rotateClass() {
            return this.$store.state.rotate ? 'rotate' : ''
        }
    }
}
</script>
<style>
.rotate {
    transform: rotate(180deg);
}
</style>